<template>
    <div class="nav">
      <a-row>
        <a-col @click="goPage('home')" class="nav-item" :class="$route.name=='home'?'on':''" :span="6">
          <img v-if="$route.name=='home'" src="@/assets/svgs/navs/home_mark.svg" alt="">
          <img v-else src="@/assets/svgs/navs/home.svg" alt="">
          <span class="text">{{$t('navs.home')}}</span>
        </a-col>
        <a-col @click="goPage('invites')" class="nav-item" :class="$route.name=='invites'?'on':''" :span="6">
          <img v-if="$route.name=='invites'" src="@/assets/svgs/navs/invite_mark.svg" alt="">
          <img v-else src="@/assets/svgs/navs/invite.svg" alt="">
          <span class="text">{{$t('navs.invite')}}</span>
        </a-col>
        <a-col @click="goPage('wallet')" class="nav-item" :class="$route.name=='wallet'?'on':''" :span="6">
          <img v-if="$route.name=='wallet'" src="@/assets/svgs/navs/wallet_mark.svg" alt="">
          <img v-else src="@/assets/svgs/navs/wallet.svg" alt="">
          <span class="text">{{$t('navs.wallet')}}</span>
        </a-col>
        <a-col @click="goPage('user')" class="nav-item" :class="$route.name=='user'?'on':''" :span="6">
          <img v-if="$route.name=='user'" src="@/assets/svgs/navs/user_mark.svg" alt="">
          <img v-else src="@/assets/svgs/navs/user.svg" alt="">
          <span class="text">{{$t('navs.user')}}</span>
        </a-col>
      </a-row>
    </div>
</template>

<script>
    export default {
        name: 'Nav',
        data(){
            return {}
        },
        props: {
            // address: String
        },
        computed: {
            // url(){
            //     return 'data:image/png;base64,' + new Identicon(Md5(this.address), 420).toString();
            // }
        },
        methods:{
          goPage(routeName){
            if(routeName != this.$route.name){
              this.$router.push({name: routeName});
            }
          }
        }
    }
</script>

<style lang="less" scoped>
.nav{
  background: #77777777;
  position: fixed;
  bottom:0;
  left:0;
  right:0;
  max-width: 640PX;
  margin: 0 auto;
  padding: 20px 0;
  color: @gray;
  font-weight: bold;
  .nav-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      height: 50px;
    }
    .text{
      padding-top: 7px;
    }
  }.on{
    color: @primary-color;
  }
}

</style>