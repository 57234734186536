<template>
<div class="myteam">
    <Logo />
    <div class="header" v-if="$route.params.paddress">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $route.params.paddress }}</span>
    </div>
    <div v-else class="flex myTotal">
        <div class="flex column item">
            <div class="bd">{{userinfo.total_team}}</div>
            <div class="label">{{$t('team.teamNum')}}</div>
        </div>
        <div class="flex column item">
            <div class="bd">{{userinfo.balances.locked_bt / 1000000}}</div>
            <div class="label">{{$t('team.locked_bt')}}</div>
        </div>
        <div class="flex column item">
            <div class="bd">{{userinfo.balances.bt / 1000000}}</div>
            <div class="label">{{$t('team.bt')}}</div>
        </div>
        <div class="flex column item">
            <div class="bd">{{userinfo.balances.gfd / 1000000}}</div>
            <div class="label">{{$t('team.gfd')}}</div>
        </div>
        <div class="flex column item">
            <div class="bd">{{userinfo.stack_amount / 1000000}}</div>
            <div class="label">{{$t('team.stack')}}</div>
        </div>
    </div>
    
    <a-row class="top" :gutter="16">
        <a-col :span="7">
            <div class="box time">
                <div>
                    {{$t('team.time')}}
                </div>
                <div class="time-arrow" @click="changeAsc">
                    <a-icon type="caret-up" :class="asc==1?'primary':''" />
                    <a-icon type="caret-down" :class="asc==0?'primary':''" />
                </div>
            </div>
        </a-col>
        <a-col :span="17">
            <a-input class="search-ipt" allowClear v-model="skey" @change="onChange" :placeholder="$t('team.searchPlaceholder')">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </a-col>
    </a-row>
    <div class="list">
        <div v-for="s,i in data" :key="'s'+i" class="box">
            <span class="tag" v-if="s.node_level>0">{{$t('profile.nodesText')[s.node_level]}}</span>
            <div class="up flex">
                <div class="address">
                    <div class="title bd"><span>{{s.address.substring(0,13)+'...'+s.address.substring(27,34)}}</span></div>
                    <div class="label"><span>{{$t('team.regTime')}}</span><span>{{s.created_at}}</span></div>
                </div>
                <div>
                    <span class="bd">{{s.sons_count}}</span>
                    <a-icon class="arrow" type="caret-right" @click="goInvites(s.address)" />
                </div>
            </div>
            <!-- <div class="bottom"> -->
            <a-row class="bottom" :gutter="10">
                <!-- <a-col class="gutter-row" :span="8">
                    <div class="bd-box flex column">
                        <div class="bd">{{s.sons_count}}</div>
                        <div class="label">{{$t('team.tjNum')}}</div>
                    </div>
                </a-col> -->
                <a-col class="gutter-row" :span="6">
                    <div class="bd-box bd-box-purple flex column">
                        <!-- <div class="text flex column"> -->
                            <div class="bd">{{s.balances.locked_bt / 1000000}}</div>
                            <div class="label">{{$t('team.locked_bt')}}</div>
                        <!-- </div> -->
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <div class="bd-box bd-box-primary flex column">
                        <div class="bd">{{s.balances.bt / 1000000}}</div>
                        <div class="label">{{$t('team.bt')}}</div>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <div class="bd-box bd-box-purple flex column">
                        <div class="bd">{{s.balances.gfd / 1000000}}</div>
                        <div class="label">{{$t('team.gfd')}}</div>
                    </div>
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <div class="bd-box bd-box-primary flex column">
                        <div class="bd">{{s.stack_amount / 1000000}}</div>
                        <div class="label">{{$t('team.stack')}}</div>
                    </div>
                </a-col>
            </a-row>  
            <!-- </div> -->
        </div>
    </div>
    <!-- <a-list
        :loading="loading"
        item-layout="vertical"
        :data-source="data"
    >
        <a-list-item class="box" slot="renderItem" slot-scope="item">
            <a-list-item-meta :description="item.address" >
                <span class="color-white" slot="title" >{{$t('profile.idTxt')}} {{ item.id }}</span>
            </a-list-item-meta>
            <a-tag class="nodeLevel" :color="$t('nodeLevel.color')[item.node_level]">
                {{$t("nodeLevel.txt")[item.node_level]}}
            </a-tag>
            <div>
                {{$t("myTeam.flowTxt")}} {{item.total_flow/1000000}}
            </div>
        </a-list-item>
        
    </a-list> -->
    <div v-if="showLoadingMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
    >
        <a-spin v-if="loading" />
        <a-button v-else type="dashed" ghost @click="getData(true)">
            {{$t('common.more')}}
        </a-button>
    </div>
    <Nav />
</div>
  
</template>
<script>
import Logo from '../components/Logo.vue';
import Nav from "../components/Nav.vue";

export default {
  name: "Invites",
  data() {
    return {
      loading: true,
      showLoadingMore: true,
      page:1,
      pageSize: 5,
      asc: 0,
      data: [],
      skey:'',
      userinfo: {total_team:0,stack_amount:0,balances:{gfd:0,locked_bt:0,bt:0}},
      paddress: this.$route.params.paddress,
    };
  },
  components: {
    Logo, Nav
  },
  mounted() {
    this.getUserinfo();
    this.getData(false);
  },
  methods: {
    getUserinfo() {
        let _this = this
        this.$http.call(this.$http.api.userinfo).then(res=>{
            _this.userinfo = res.data.data;
            _this.$store.commit('setUserinfo', res.data.data);
        },res=>{
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        });
    },
    getData(append = true){
        this.loading = true;
        this.$http.call(this.$http.api.teams,{
            data:{page:this.page,size:this.pageSize,sort:this.asc,skey:this.skey,paddress:this.paddress}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                if(append){
                    this.data = this.data.concat(resp.data);
                }else{
                    this.data = resp.data;
                }
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    },
    onSearch(value){
        console.log(value,this.skey)
        this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    onChange(){
        // console.log(value,this.skey)
        // this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    changeAsc(){
        this.asc = !this.asc;
        this.page = 1;
        this.getData(false);
    },
    goRecords(address){
        this.$router.push({name: 'records', params:{address: address}})
    },
    goInvites(address){
        this.$router.push({name: 'invites', params:{paddress: address}})

    }
  },
};
</script>
<style lang="less">
.myteam{
    padding: 0 50px;
    width: 100%;
    padding-bottom: 168px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .header{
        position:relative;
        color: #fff;
        font-size: 16PX;
        display:flex;
        align-items: center;
        padding-bottom: 30PX;
        .back{
            position:absolute;
            left: 0;
            color:#fff;
        }
        .title{
            font-size: 12PX;
            flex: 1;
        }
    }
    .top{
        display: flex;
        align-items: center;
        margin-bottom: 20PX;
        .time{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10PX 20PX;
            border-radius: 15PX;
            margin: 0;
            .time-arrow{
                display: flex;
                flex-direction: column;
                justify-content: center;
                i:first-child{
                    margin-bottom: -3PX;
                }
                i:last-child{
                    margin-top: -3PX;
                }
            }
        }
        .search-ipt{
            .ant-input{
                padding: 12PX 20PX 10PX 38PX;
                height: auto;
                border: 0;
                border-radius: 15PX;
            }
            .ant-input-suffix{
                color: @white;
            }
            .ant-input-search-icon{
                color: @white;
            }
            .ant-input-clear-icon{
                color: @white;
            }
        }
    }
    .list {
        overflow-y: scroll;
    }
    .box {
        padding: 30px;
        margin-bottom: 30px;
        font-size: 36px;
        position: relative;
        overflow: hidden;
        .tag{
            position: absolute;
            top: 0;
            right:0;
            background: @primary-color-shadow;
            padding: 10px 24px;
            font-size: 30px;
            border-bottom-left-radius: 24px;
        }
        .up{
            padding-bottom: 15PX;
            .address{
                text-align: left;
                .title{
                    font-size: 16PX;
                }
            }
        }
        .bottom{
            text-align: left;
            .bd-box{
                // border: 1PX solid @white*0.5;
                border-radius: 6PX;
                padding: 6PX;
                background: #f8f8f8;
                .bd {
                    color: @primary-color;
                    font-weight: bold;
                }
            }
            // .bd-box-primary{
            //     border: 1PX solid @primary-color-shadow;
            //     border-radius: 6PX;
            //     // padding: 8PX 20PX;
            //     flex: 1;
            //     .text.flex{
            //         align-items: flex-start;
            //     }
            // }
            // .bd-box-purple{
            //     border: 1PX solid @purple;
            //     border-radius: 6PX;
            // }
        }
        .label{
            font-size: 12PX;
        }
        .bd{
            font-weight: 700;
        }
        .primary{
            color: @primary-color;
        }
    }.myTotal{
        color: @primary-color;
        padding: 20PX;
        background: #fff;;
        border-radius: 15PX;
        margin-bottom: 15PX;
    }
}
</style>
